import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import BootstrapVue3 from 'bootstrap-vue-3' // Import BootstrapVue 3
import SideMenuPlugin from './components/SideMenu/SideMenu.vue' // Ensure this path is correct

import VueAwesomePaginate from 'vue-awesome-paginate' // Import Vue Awesome Paginate
import 'vue-awesome-paginate/dist/style.css' // Import Vue Awesome Paginate CSS

import axios from 'axios'

// Create an instance of axios
const api = axios.create({
  baseURL: 'http://localhost:5173/', // Set your base URL
  headers: {
    'Content-Type': 'application/json'
  }
})

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message === 'Invalid token') {
        // Clear token and tokenExpiration from localStorage
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpiration')

        // Redirect to login page with a message
        router.push({
          name: 'login',
          query: { message: 'Session expired. Please log in again.' }
        })
      }
    }
    return Promise.reject(error)
  }
)

const app = createApp(App)

app.use(router)
app.use(BootstrapVue3) // Use BootstrapVue 3
app.use(VueAwesomePaginate) // Use Vue Awesome Paginate

// Router navigation guards
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  const tokenExpiration = localStorage.getItem('tokenExpiration')
  const now = new Date().getTime()

  if (to.path === '/scan' && token && tokenExpiration && now < parseInt(tokenExpiration)) {
    next('/admin/scanadv')
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token && tokenExpiration) {
      if (now >= parseInt(tokenExpiration)) {
        // Token expired, redirect to login
        localStorage.removeItem('token') // Clear the token
        localStorage.removeItem('tokenExpiration') // Clear the token expiration
        next({
          name: 'login',
          query: { message: 'Session expired. Please log in again.' }
        })
      } else {
        next()
      }
    } else {
      next('/login') // No token, redirect to login
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (token && tokenExpiration) {
      if (now < parseInt(tokenExpiration)) {
        next('/admin/dashboard')
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

app.mount('#app')

app.use(SideMenuPlugin, { sidemenuLinks: [] }) // Pass necessary options
