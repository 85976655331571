<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.checkTokenExpiration(); // Start checking for token expiration when the component is mounted
  },
  methods: {
    checkTokenExpiration() {
      setInterval(() => {
        const tokenExpiration = localStorage.getItem('tokenExpiration');
        const now = new Date().getTime();

        if (tokenExpiration && now >= parseInt(tokenExpiration)) {
          // Token has expired, log the user out
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          this.$router.push({
            name: 'login',
            query: { message: 'Session expired. Please log in again.' }
          });
        }
      }, 10000); // Check every 10 seconds
    }
  }
}


</script>
