<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="copyright footer-link">
        © {{ year }} <a href="https://www.stiqr.com.au" target="_blank">Stiqr</a>
      </div>
      <ul class="nav-footer">
        <li class="footer-link">
          <a href="https://www.stiqr.com.au/about" target="_blank">About Us</a>
        </li>
        <li class="footer-link">
          <a href="https://www.stiqr.com.au/termsandconditions" target="_blank">Terms & Conditions</a>
        </li>
        <li class="footer-link">
          <a href="https://www.stiqr.com.au/contact" target="_blank">Contact Us</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.footer {
  background-color: #3f4066;
  padding: 1rem;
  /* Adjust padding as needed */
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  /* Adjust side padding */
}

.copyright {
  color: #87cafc;
  font-weight: bold;
}

.nav-footer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  margin-right: 15px;
}

.footer-link:last-child {
  margin-right: 0;
}

.footer-link a {
  color: #87cafc;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-link a:hover {
  color: #d1e9fd;
}
</style>
