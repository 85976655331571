<template>

  <div class="wrapper">

    <side-menu class="side-menu"></side-menu>

    <div class="main-content">
      <top-menu></top-menu>
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>


    </div>

  </div>
  <div class="content-footer">
    <content-footer v-if="!$route.meta.hideFooter"></content-footer>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu/SideMenu.vue'; // Ensure this path is correct
import TopMenu from './TopMenu.vue';
import ContentFooter from './ContentFooter.vue';

export default {
  components: {
    SideMenu,
    TopMenu,
    ContentFooter,
  },
};
</script>

<style lang="scss">
/* Custom styles can be added here */
.wrapper {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  background-color: #D1E9FD;
}

.content-footer {
  background-color: #3f4066;
  bottom: 0;
  width: 100%;
  /* Adjust padding as needed */
}
</style>
