<template>
  <div class="scan-content">
    <!-- Navigation bar -->
    <nav class="topMenu-auth topMenu-horizontal justify-content-between" expand="lg">
      <div class="topMenu-wrapper">
        <router-link to="/" class="topMenu-brand">
          <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
          <!-- Floating Squares Background under the logo -->
          <div class="background"></div>
        </router-link>
      </div>
      <ul class="topMenu-nav align-items-lg-center">
        <li class="nav-item">
          <router-link to="/home" class="nav-link-auth">Home</router-link>
        </li>
      </ul>
    </nav>

    <div class="content-container">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <footer class="footer">
      <div class="footer-content">
        <div class="copyright footer-link">
          © {{ year }} <a href="https://www.stiqr.com.au" target="_blank">Stiqr</a>
        </div>
        <ul class="nav-footer">
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/about" target="_blank">About Us</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/termsandconditions" target="_blank">Terms & Conditions</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/contact" target="_blank">Contact Us</a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>



<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleTopMenu() {
      document.body.classList.toggle('menu-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('menu-open');
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add('bg-default');
    },
    removeBackgroundColor() {
      document.body.classList.remove('bg-default');
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
    createFloatingSquares() {
      const background = this.$el.querySelector(".background");
      const numSquares = 100;
      const logoRect = this.$el.querySelector(".topMenu-brand").getBoundingClientRect();

      for (let i = 0; i < numSquares; i++) {
        const square = document.createElement("div");
        square.classList.add("square");

        // Start position relative to the logo container
        square.style.left = `${logoRect.left + Math.random() * logoRect.width}px`;
        square.style.top = `${logoRect.top + Math.random() * logoRect.height}px`;

        square.dataset.dx = Math.random() * 2 - 1;
        square.dataset.dy = Math.random() * 2 - 1;

        background.appendChild(square);
      }
    },
    animateSquares() {
      const squares = this.$el.querySelectorAll(".square");

      squares.forEach(square => {
        let x = parseFloat(square.style.left);
        let y = parseFloat(square.style.top);
        let dx = parseFloat(square.dataset.dx);
        let dy = parseFloat(square.dataset.dy);

        x += dx;
        y += dy;

        if (x <= 0 || x >= window.innerWidth - 20) {
          dx *= -1;
        }
        if (y <= 0 || y >= window.innerHeight - 20) {
          dy *= -1;
        }

        square.style.left = `${x}px`;
        square.style.top = `${y}px`;
        square.dataset.dx = dx;
        square.dataset.dy = dy;
      });

      requestAnimationFrame(this.animateSquares);
    }
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
  mounted() {
    this.createFloatingSquares();
    this.animateSquares();
  },
};
</script>

<style lang="scss">
.scan-content {
  position: relative;
  overflow: hidden;
}

/* Floating Squares Styles */
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  top: 0;
  left: 0;
}

.square {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.content-container,
.footer {
  position: relative;
  z-index: 1;
}

.topMenu-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20%;
}

.topMenu-nav {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.nav-link-auth {
  color: #3f4066;
  margin-right: 15px;
  transition: color 0.3s ease;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.nav-link-auth:hover {
  color: #87cafc;
}

/* Footer Styles */
.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-footer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  margin-right: 15px;
}

.footer-link:last-child {
  margin-right: 0;
}

.footer-link a {
  color: #d1e9fd;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-link a:hover {
  color: #87cafc;
}
</style>
