import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/views/Layout/MainLayout.vue'
import AuthLayout from '@/views/Pages/AuthLayout.vue'
import ScanLayout from '@/views/Pages/ScanLayout.vue'
import NotFound from '@/views/NotFoundPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: ScanLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Stiqrs/HomePage.vue')
      },
      {
        path: 'scan',
        name: 'scan',
        meta: { requiresGuest: true },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Stiqrs/FreeScan.vue')
      },
      {
        path: 'code/:code',
        name: 'code',
        meta: { requiresGuest: true },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Stiqrs/CodePage.vue')
      },
      {
        path: 'public/:code',
        name: 'publicCode',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Stiqrs/PublicCodePage.vue')
      }
    ]
  },
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        meta: { requiresGuest: true },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/LoginPage.vue')
      }
    ]
  },
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    component: MainLayout,
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue')
      },
      {
        path: 'scanadv',
        name: 'scanadv',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/scanadv.vue')
      },
      {
        path: 'code/:code',
        name: 'RegisteredCode',
        meta: { requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "demo" */ '../views/Stiqrs/RegisteredCodePage.vue')
      },
      {
        path: 'public/:code',
        name: 'AdminPublicCode',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Stiqrs/PublicCodePage.vue')
      },
      {
        path: 'generateqr',
        name: 'generateqr',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/generateqr.vue')
      },
      {
        path: 'mystiqrs',
        name: 'mystiqrs',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/mystiqrs.vue')
      },
      {
        path: 'mystiqrsadmin',
        name: 'mystiqrsadmin',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/mystiqrsAdmin.vue')
      },
      {
        path: 'printqr',
        name: 'printqr',
        meta: { requiresAuth: true },
        component: () => import('../views/Stiqrs/PrintQr.vue')
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { requiresAuth: true },
        component: () => import('../views/Pages/UserProfile.vue')
      }
    ]
  },
  // Update the catch-all route for Vue Router 4
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
