<template>
  <div class="scan-content">
    <!-- Navigation bar -->
    <nav class="topMenu topMenu-horizontal topMenu-main topMenu-top topMenu-dark justify-content-between" expand="lg">
      <div class="topMenu-wrapper">
        <router-link to="/" class="topMenu-brand">
          <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
        </router-link>
      </div>
      <ul class="topMenu-nav align-items-lg-center">
        <li class="nav-item">
          <router-link to="/register" class="nav-link">
            <!-- <i class="ni ni-circle-08"></i> -->
            Register
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/login" class="nav-link">
            <!-- <i class="ni ni-key-25"></i> -->
            Login
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="content-container">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <footer class="footer px-4">
      <div class="footer-content">
        <div class="copyright footer-link">
          © {{ year }} <a href="https://www.stiqr.com.au" target="_blank">Stiqr</a>
        </div>
        <ul class="nav-footer">
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/about" target="_blank">About Us</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/termsandconditions" target="_blank">Terms & Conditions</a>
          </li>
          <li class="footer-link">
            <a href="https://www.stiqr.com.au/contact" target="_blank">Contact Us</a>
          </li>
        </ul>
      </div>
    </footer>

  </div>
</template>

<script>
export default {
  name: "ScanLayout",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.scan-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #d1e9fd;
}

.content-container {
  flex: 1;
  width: 100%;
}

.footer {
  background-color: #3f4066;
  padding: 1rem 0;
}

.footer .nav-footer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}



.topMenu-nav {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.nav-link {
  color: #3f4066;
  margin-right: 15px;
  transition: color 0.3s ease;
  font-weight: bold;
}

.nav-link:hover {
  color: #87cafc;
}

.topMenu-brand img {
  height: 100px;
}

.footer {
  background-color: #3f4066;
  padding: 1rem 0;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.copyright {
  color: #87cafc;
  font-weight: bold;
}

.nav-footer {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  margin-right: 15px;
}

.footer-link:last-child {
  margin-right: 0;
}

.footer-link a {
  color: #87cafc;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-link a:hover {
  color: #d1e9fd;
}
</style>
