<template>
  <nav class="custom-menu">
    <div class="custom-menu-content">
      <!-- Display the current route name as the title on the left side -->
      <div class="custom-menu-left">
        <span class="custom-route-name">{{ routeName }}</span>
      </div>

      <!-- Search bar in the center of the menu -->
      <div class="custom-menu-center">
        <div class="custom-search-container">
          <input type="text" placeholder="Search" v-model="searchQuery" @keyup.enter="performSearch" />
          <i class="fas fa-search custom-search-icon"></i>
        </div>
      </div>

      <!-- User profile dropdown menu on the right -->
      <div class="custom-menu-right">
        <!-- Toggles dropdown visibility when clicked -->
        <div class="custom-profile-dropdown" @click="toggleDropdown">
          <div class="custom-profile-info">
            <!-- Display user avatar and name -->
            <img src="@/assets/favicon.png" class="custom-avatar" />
            <span class="custom-username">{{ fullName }}</span>
          </div>

          <!-- Dropdown menu for user actions -->
          <ul :class="{ 'custom-dropdown-menu': true, 'visible': showDropdown }">
            <li class="custom-dropdown-header">WELCOME!</li>
            <li>
              <!-- Navigation links to user-related pages -->
              <router-link to="/profile">My profile</router-link>
            </li>
            <li>
              <router-link to="/settings">Settings</router-link>
            </li>
            <li>
              <router-link to="/activity">Activity</router-link>
            </li>
            <li>
              <router-link to="/support">Support</router-link>
            </li>
            <li class="custom-dropdown-divider"></li>
            <li>
              <!-- Logout action -->
              <a href="#!" @click="logout">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue"; // Import Vue's composition API

export default {
  name: "TopMenu",
  setup() {
    // Reactive state variables
    const showDropdown = ref(false); // Controls dropdown visibility
    const searchQuery = ref(""); // Holds the search query text
    const userAvatar = ref(); // User avatar image
    const fullName = ref("Adrian Paul"); // User full name (dummy data for demonstration)

    // Toggles the dropdown menu visibility
    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    // Closes the dropdown if a click is detected outside of it
    const closeDropdown = (event) => {
      if (!event.target.closest(".custom-profile-dropdown")) {
        showDropdown.value = false;
      }
    };

    // Performs the search action when triggered
    const performSearch = () => {
      console.log("Searching for:", searchQuery.value);
      // Implement search functionality here
    };

    // Clears user data and redirects to login on logout
    const logout = () => {
      localStorage.clear(); // Clear local storage
      window.location.href = "/login"; // Redirect to login page
    };

    // Setup event listeners when the component is mounted
    onMounted(() => {
      document.addEventListener("click", closeDropdown); // Listen for clicks to close dropdown
    });

    // Clean up event listeners before the component is unmounted
    onBeforeUnmount(() => {
      document.removeEventListener("click", closeDropdown);
    });

    return {
      showDropdown,
      searchQuery,
      userAvatar,
      fullName,
      toggleDropdown,
      performSearch,
      logout,
    };
  },
  computed: {
    // Computes and formats the route name to display
    routeName() {
      const { name } = this.$route;
      return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
    },
  },
};
</script>

<style scoped>
/* Styles for the entire menu bar */
.custom-menu {
  background-color: #d1e9fd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Container for the menu sections */
.custom-menu-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Styles for each section of the menu */
.custom-menu-left,
.custom-menu-center,
.custom-menu-right {
  display: flex;
  align-items: center;
}

/* Route name styling */
.custom-route-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Search bar styling */
.custom-search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-search-container input {
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  outline: none;
  width: 200px;
}

.custom-search-icon {
  position: absolute;
  right: 10px;
  color: #999;
}

/* Profile dropdown styling */
.custom-profile-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-profile-info {
  display: flex;
  align-items: center;
}

/* Avatar styling */
.custom-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Username styling */
.custom-username {
  font-size: 14px;
  color: #333;
}

/* Dropdown menu styling */
.custom-dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.custom-dropdown-menu.visible {
  opacity: 1;
  visibility: visible;
}

.custom-dropdown-menu li {
  padding: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

.custom-dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.custom-dropdown-header {
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

.custom-dropdown-divider {
  border-top: 1px solid #ddd;
  margin: 5px 0;
}
</style>
