<template>

  <!-- Sidebar -->
  <nav class="sidemenu">
    <div class="topMenu-wrapper">
      <router-link to="/" class="topMenu-brand">
        <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
      </router-link>
    </div>
    <ul class="inner-menu">
      <li v-for="item in menuItems" :key="item.name">
        <router-link :to="item.path">
          <i :class="item.icon"></i> {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>

  <!-- Main Content -->


</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    logo: {
      type: String,
      default: 'src/logo.svg'
    },
    menuItems: {
      type: Array,
      default: () => [
        { name: 'Dashboard', path: '/admin/dashboard', icon: 'ni ni-tv-2' },
        { name: 'Scan StiQR', path: '/admin/scanadv', icon: 'ni ni-camera-compact' },
        { name: 'My StiQRs', path: '/admin/mystiqrs', icon: 'ni ni-bullet-list-67' },
        { name: 'My StiQRs Admin', path: '/admin/mystiqrsAdmin', icon: 'ni ni-bullet-list-67' },
        { name: 'Generate StiQR', path: '/admin/generateqr', icon: 'ni ni-atom' },
        { name: 'Print StiQR', path: '/admin/printqr', icon: 'ni ni-image' }
      ]
    }
  }
}
</script>

<style scoped>
.sidemenu {
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  top: 60px;
  left: 0;
  overflow-y: auto;
  z-index: 100;
}

.inner-menu {
  list-style: none;
  padding: 1rem;
  margin: 0;
  width: 100%;
  display: grid;
  width: 200px;
  justify-content: center;
}

.logo-img {
  height: 50px;
}

.inner-menu li {
  margin-bottom: 15px;
}

.inner-menu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #3f4066;
  font-weight: bold;
  transition: color 0.3s ease;
  text-decoration: none;
}

.inner-menu a:hover {
  color: #87cafc;
}

.inner-menu i {
  margin-right: 10px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}
</style>
